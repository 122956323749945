.knots {
  &Title {
    line-height: 60px;
    padding-left: 44px;
    background: rgba(255, 255, 255, 0.2) url('../../../../assets/newImg/homePageImg/hp-map-position@2x.png') 18px center no-repeat;
    background-size: 23px 23px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  &List {
    height: 364px;
    line-height: 48px;
    padding: 15px 20px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-top: none;
  }

  &Pagination {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-top: none;
    color: #fff;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);

    :global {

      .ant-pagination-prev,
      .ant-pagination-next,
      .ant-pagination-jump-prev,
      .ant-pagination-jump-next,
      .ant-pagination-prev button,
      .ant-pagination-next button,
      .ant-pagination-prev:focus-visible .ant-pagination-item-link,
      .ant-pagination-next:focus-visible .ant-pagination-item-link,
      .ant-pagination-prev:hover .ant-pagination-item-link,
      .ant-pagination-next:hover .ant-pagination-item-link {
        color: #fff;
      }

      .ant-pagination-simple-pager input {
        background-color: rgba(255, 255, 255, 0.08);
        border: none;
      }
    }
  }

}