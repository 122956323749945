.sec {
  padding-bottom: 69px;
}

.title {
  height: 40px;
  margin-bottom: 69px;

  &Data {
    background: url('../../../../../assets/assetCopyright/dataTitle.png') 0 0 no-repeat;
    background-size: contain;
  }

  &CopyRight {
    background: url('../../../../../assets/assetCopyright/copyrightTitle.png') 0 0 no-repeat;
    background-size: contain;
  }
}