.card {
  flex: 0 0 20%;
  margin-bottom: 20px;
}

.service {
  height: 240px;
  width: 205px;
  box-shadow: 0px 8px 16px 0px rgba(22, 22, 64, 0.04);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &Logo {
    height: 80px;
    width: 80px;
    margin-left: 84px;
    margin-top: 98px;
  }

  &Name {
    font-size: 24px;
    font-family: SourceHanSansSC, SourceHanSansSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #fff;
    line-height: 33px;
    margin-bottom: 8px;
    padding-top: 108px;
  }

  &Desc {
    font-size: 16px;
    font-family: SourceHanSansSC, SourceHanSansSC-Regular;
    font-weight: 400;
    text-align: center;
    color: rgba(22, 22, 64, 0.65);
    line-height: 24px;
  }
}

.infomation {
  display: flex;
  align-items: center;
  margin-top: -240px;
  height: 240px;
  width: 205px;
  opacity: 0;
  position: absolute;
  font-size: 16px;
  border-radius: 10px;
  font-family: SourceHanSansSC, SourceHanSansSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 24px;
  background: rgba(22, 22, 64, 0.65);
  transition: opacity 1s;

  .content {
    width: 252px;
    padding: 0 16px;
    font-size: 16px;
    font-family: SourceHanSansSC, SourceHanSansSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 24px;
  }

  &:hover {
    opacity: 1;
  }
}
