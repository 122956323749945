.news {
  width: 340px;
  display: flex;
  margin: 26px 0px 26px 0;
  background-color: #fff;
  // margin-bottom: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
  padding-left: 12px;
  cursor: pointer;
  border: 1px solid #0000000a;
  justify-content: space-between;
  border-radius: 4px;
  
  .newsLeft {
    // justify-content: center;
    text-align: center;
    // vertical-align: center;

    .newsImg {
      border-radius: 4px;
      width: 300px;
      height: 210px;
    }
  }

  .newsTitle {
    width: 310px;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #153055;
    line-height: 25px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .newsDate {
    // margin-left: 40px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: right;
    color: rgba(21, 48, 85, 0.38);
    line-height: 20px;
    letter-spacing: 0px;
  }

  .newsSubTitle {
    height: 66px;
    margin-top: 12px;
    margin-left: 40px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(21, 48, 85, 0.65);
    line-height: 22px;
    letter-spacing: 0px;
  }
}

.news:hover {
  background: rgba(255, 255, 255, 0.60);
  border: 1px solid rgba(51, 95, 255, 0.20);
  border-radius: 4px;
  box-shadow: 0px 6px 12px 0px rgba(51, 95, 255, 0.12);
}