.fullResult {
  // margin: 0 auto;
  height: 778px;

  .backLink {
    padding: 15px 0;

    &.backCenter {
      width: 64%;
      position: relative;
      top: 80px;
      margin: 0 auto;
    }

    :global {
      a {
        font-size: 15px;
        font-weight: 400;
        color: rgba(22, 22, 64, 0.87);
        line-height: 21px;
      }
    }
  }
}


.card {
  margin-top: 14px;
  padding-top: 60px;
  padding-bottom: 60px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 20px 40px 0px rgba(21, 48, 85, 0.04);
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  backdrop-filter: blur(10px);
}

.succTips {
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #161640;
}

.succIcon {
  font-size: 32px;
  margin-right: 16px;
  line-height: 32px;
  color: #3BB336;
}

.succDescriptions {
  :global {
    .ant-descriptions-item-label {
      width: 90px;
      justify-content: end;
    }
  }
}

.block {
  width: 50%;
  padding: 20px 20px 8px 20px;
  // background: rgba(0, 10, 39, 0.70);
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  position: relative;
  top: 30px;
  margin: 0 auto;
  margin-bottom: 20px;

  .title {
    height: 24px;
    //width: 72px;
    //text-align: center;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    color: rgba(22, 22, 64, 0.87);
    line-height: 25px;
    border-bottom: 10px solid rgba(51, 95, 255, 0.2);
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {

  .card {
    background: none;
    border: none;
    box-shadow: none;
  }

  .block {
    width: 343px;
    background: rgba(255, 255, 255, 0.4);
  }
}
