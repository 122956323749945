:global {
  .logo {
    height: 40px;
    width: 152px;
    background-size: cover;
  }

  .fp-tableCell {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  #menu {
    display: flex;
    list-style-type: none !important;

    li {
      text-decoration: none;
      position: relative;
      margin-left: 48px;
    }

    .active {
      a {
        opacity: 1 !important;
      }

      &:after {
        position: absolute;
        content: '';
        width: 120%;
        height: 2px;
        border-radius: 1px;
        bottom: -6px;
        left: -10%;
      }
    }
  }


  // .sum-mobile {
  //   background: rgba(0,22,84,0.70);
  //   border: 1px solid rgba(104,153,255,0.50);
  //   height: 44px;
  //   width: 100%;
  //   line-height: 44px;
  //   text-align: center;
  //   color: #fff;
  // }
}

header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  height: 80px;
  width: 100%;
  z-index: 1;
}

.nav {
  height: 100%;
  width: 1200px;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.section(@img) {
  background-image: @img;
  background-size: cover;
  display: flex !important;
  justify-content: center;
}

.container_card {
  max-width: 1200px;
  height: 100%;
  width: 100%;
}

.title_card(@color) {
  font-size: 32px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: @color;
  margin-bottom: 36px;
}

.index {
  .section(image-set(url(../assets/architectural-architectural-design-architecture-bridge-532572备份.png) 1x, url(../assets/architectural-architectural-design-architecture-bridge-532572备份@2x.png) 2x));

  :global {

    .container {
      .container_card;

      display: flex;
      position: relative;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .title {
      color: #ffffff;
      font-size: 64px;
      font-family: PingFangTC, PingFangTC-Semibold;
      font-weight: 600;
      height: 98px;
      line-height: 90px;
    }

    .desc {
      line-height: 33px;
      letter-spacing: 1px;
      color: #ffffff;
      font-family: SourceHanSansSC, SourceHanSansSC-Medium;
      font-size: 24px;
      font-weight: 500;
    }

    .next {
      position: absolute;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      bottom: 88px;
    }

    .arrow:local {
      position: absolute;
      bottom: 60px;
      animation-name: shake;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
}

.intro {
  .section(image-set(url(../assets/位图_矩形_蒙版.png) 1x, url(../assets/位图_矩形_蒙版@2x.png) 2x));

  :global {
    .container {
      .container_card;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h3 {
      .title_card(#1a2b61);
    }

    .desc {
      width: 536px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #1a2b61;
      padding: 25px 36px;
      border-radius: 6px;
      border: 1px solid #1a2b61;
    }
  }
}

.enter {
  .section(image-set(url(../assets/位图_8.png) 1x, url(../assets/位图_8@2x.png) 2x));

  :global {
    .ant-carousel {
      width: 960px;
    }

    .ant-carousel .slick-dots-bottom {
      bottom: -35px !important;
      margin-right: 0% !important;
      left: auto !important;
    }

    .container {
      .container_card;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    h3 {
      .title_card(#fff);
    }

    .desc {
      background-size: cover;
      background-image: image-set(url(../assets/矩形_2.png) 1x, url(../assets/矩形_2@2x.png) 2x);
      width: 445px;
      height: 399px;
      box-sizing: border-box;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #fff;
      padding: 36px 30px 0 30px;

      span {
        opacity: 0.9;
        display: block;
      }
    }

    h4 {
      font-size: 22px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .address {
      margin-bottom: 12px;
    }

    .phone {
      margin-bottom: 50px;
    }

    .map {
      display: flex;
      align-items: center;
      width: 960px;
    }
  }
}

.service {
  .section(image-set(url(../assets/位图.png) 1x, url(../assets/位图@2x.png) 2x));

  :global {
    .container {
      .container_card;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    h3 {
      .title_card(#1a2b61);
    }

    .panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #1a2b61;
      margin: 0 24px 18px 0;
      width: 274px;
      height: 200px;
      border-radius: 5px;
      border: 1px solid rgba(20, 49, 119, 0.40);
      padding: 24px 40px 0 40px;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        border: 1px solid #3263eb;
        background: rgba(255, 255, 255, 0.80);
        box-shadow: 0px 20px 30px 0px rgba(25, 51, 85, 0.10);
      }
    }

    .serviceInfo {
      display: flex;
      flex-wrap: wrap;
    }

    img {
      margin-bottom: 18px;
    }

    h5 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 13px;
    }

    span {
      text-align: center;
    }
  }
}

.bgc {
  background: #05060F !important;
}

.check {
  .section(image-set(url(../assets/位图_7.png) 1x, url(../assets/位图_7@2x.png) 2x));

  :global {
    .container {
      .container_card;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
    }

    h3 {
      .title_card(white);
      margin-bottom: 63px;
    }

    input {
      height: 68px;
      width: 900px;
      border-radius: 7px;
      margin-bottom: 60px;
      font-size: 20px;
    }

    button {
      height: 60px;
      width: 256px;
      font-size: 22px;
      background: #3263eb;
    }

    .result {

      .info {
        width: 460px;
        background: rgba(0, 10, 39, 0.7);
        border: 1px solid #68bcff;
        border-radius: 5px;
        padding: 36px 36px 16px 36px;

        .ant-descriptions-item-label,
        .ant-descriptions-item-content {
          color: white;
        }

        .ant-descriptions-row>th,
        .ant-descriptions-row>td {
          margin-bottom: 20px;
          padding-bottom: 20px
        }
      }

      .info-cert {
        width: 460px;
        background: rgba(0, 10, 39, 0.7);
        border: 1px solid #68bcff;
        border-radius: 5px;
        padding: 24px 36px 12px 36px;

        .ant-descriptions-item-label,
        .ant-descriptions-item-content {
          color: white;
        }

        .ant-descriptions-row>th,
        .ant-descriptions-row>td {
          margin-bottom: 12px;
          padding-bottom: 12px
        }
      }

      .info-position-left {
        left: 0;
        top: 104px;
        position: absolute;
      }

      .info-position-right {
        right: 60px;
        position: absolute;
        top: 104px;
        display: flex;
        flex-direction: column;
      }

      video {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 1000px;
        height: 500px;
      }

      li {
        margin-bottom: 20px;
        color: #fff;
        list-style-type: none;
        word-wrap: break-word;
        word-break: normal;
      }

      .a-button {
        height: 44px;
        background: rgba(7, 40, 132, 0.25);
        border: 1px solid #68bcff;
        line-height: 44px;
        text-align: center;
        display: inline-block;
        color: white;
      }

      .sum {
        .a-button;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 20px;
      }

      .cert {
        .a-button;
        width: 460px;
      }
    }
  }
}

.industry {
  .section(image-set(url(../assets/位图_1.png) 1x, url(../assets/位图_1@2x.png) 2x));

  :global {
    .container {
      .container_card;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    h3 {
      .title_card(#1a2b61);
    }

    .industryInfo {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .panel {
      width: 180px;
      height: 180px;
      position: relative;
      border-radius: 4px;
    }

    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background: transparent;
      cursor: pointer;

      &:hover:local {
        animation-name: bgdchange;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }

      &:hover .desc:local {
        animation-name: moveup;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }

    .desc {
      z-index: 2;
      position: absolute;
      height: 44px;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.40);
      border-radius: 0px 0px 4px 4px;
      text-align: center;
      line-height: 44px;
      color: white;
    }
  }
}

.contact {
  .section(image-set(url(../assets/编组_10.png) 1x, url(../assets/编组_10@2x.png) 2x));

  :global {
    .container {
      .container_card;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      footer {
        color: rgba(0, 0, 0, 0.58);
        margin-top: 55px;
        margin-bottom: 17px;
      }
    }

    .content {
      width: 572px;
      padding: 40px 60px 60px 60px;
      position: relative;
      background-color: black;
      background: rgba(4, 20, 34, 0.50);
      border-radius: 4px;
      text-align: center;

      h3 {
        .title_card(white);
      }

      label {
        color: white;
        font-size: 16px;
      }

      textarea,
      input {
        background-color: transparent;
        border: 0px;
        border-bottom: 1px solid #e1e1e1 !important;
        color: white;
      }

      .ant-input:focus,
      .ant-input:hover,
      .ant-input:active {
        box-shadow: none;
        background-color: transparent;
      }

      textArea {
        height: 125px;
      }

      button {
        position: absolute;
        width: 454px;
        height: 60px;
        left: 59px;
        bottom: -30px;
        font-size: 22px;
        background: #3263eb;
        border-radius: 7px;
      }
    }
  }
}

.mobile {
  background-image: image-set(url(../assets/mobile.png) 1x, url(../assets/mobile@2x.png) 2x);
  background-size: 100% 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :global {
    h3 {
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 40px;
    }

    input {
      height: 48px;
      border-radius: 7px;
      margin-bottom: 60px;
      margin-bottom: 40px;
    }

    button {
      height: 36px;
      width: 200px;
      background: #3263eb;
      border-radius: 7px
    }

    img {
      position: absolute;
      bottom: 35px;
    }

    .block {
      width: 100%;
      padding: 28px 20px 8px 20px;
      background: rgba(0, 10, 39, 0.70);
      border: 1px solid rgba(104, 153, 255, 0.50);
      border-radius: 5px;
      position: relative;
      margin-bottom: 28px;

      .title {
        position: absolute;
        transform: translate(0, -50%);
        top: 0;
        left: 0;
        height: 32px;
        opacity: 1;
        background: #3263eb;
        border-radius: 4px 4px 4px 0px;
        width: 96px;
        text-align: center;
        line-height: 32px;
        color: white;
      }
    }

    video {
      width: 100%;
      margin-bottom: 28px;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      color: white;
    }

    .ant-descriptions-row>th,
    .ant-descriptions-row>td {
      margin-bottom: 12px;
      padding-bottom: 12px
    }

    .btn-position {
      width: 96px;
      height: 32px;
      background: #072884;
      border: 1px solid rgba(255, 255, 255, 0.30);
      border-radius: 5px;
      top: 16px;
      right: 20px;
      position: absolute;
    }
  }

  .backLink {
    position: relative;
    top: -20px;
    color: #fff;
  }
}

:global {
  .light {

    a {
      color: white;
      border-color: white;
      opacity: 0.75;
    }

    a:hover {
      opacity: 1 !important;
    }

    .active {
      &:after {
        background: white;
      }
    }

    .ant-divider,
    .ant-divider-vertical {
      margin: 0 4px;
      background: white;
      opacity: 0.75;
    }

    .logo {
      background-image: image-set(url(../assets/编组_20.png) 1x, url(../assets/编组_20@2x.png) 2x);
    }
  }

  .dim {
    a:hover {
      color: #3263eb;
    }

    a {
      color: rgba(0, 0, 0, 0.58);
      border-color: rgba(0, 0, 0, 0.58);
    }

    .active {
      a {
        color: #3263eb;
      }

      &:after {
        background: #3263eb;
        ;
      }
    }

    .logo {
      background-image: image-set(url(../assets/编组_22.png) 1x, url(../assets/编组_22@2x.png) 2x);
    }
  }
}

:global {

  .animated1,
  .animated2,
  .animated3,
  .animated4,
  .animated5,
  .animated6,
  .animated7 {
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  .delay1 {
    animation-delay: 0.3s;
  }

  .delay2 {
    animation-delay: 0.5s;
  }

  .delay3 {
    animation-delay: 0.7s;
  }

  .fadeinup:local {
    animation-name: fadeinup;
  }
}

@keyframes bgdchange {
  from {
    background: transparent;
  }

  to {
    background: rgba(0, 0, 0, 0.30);
  }
}

@keyframes moveup {
  from {
    background: rgba(0, 0, 0, 0.40);
    bottom: 0;
  }

  to {
    background: #3263EB;
    bottom: 79px;
  }
}

@keyframes shake {
  0% {
    bottom: 60px;
  }

  100% {
    bottom: 45px;
  }
}

@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.document {

  :global {
    // .react-pdf__Page__canvas {
    //   width: 100% !important;
    //   height: 100% !important;
    // }

    .react-pdf__Page__textContent {
      position: fixed !important;
    }
  }
}

.footerRecordNumber {
  padding-bottom: 20px;
  font-size: 10px;
  line-height: 14px;
  opacity: 0.7;
}

.certImg {
  margin: 0 auto;
  position: relative !important;
  width: 40%;
  bottom: 0px !important;
}

.resultWithCertImg {
  // height: 1275px;

  >.blockImg {
    width: 100%;
    padding: 28px 20px 8px 20px;
    background: rgba(0, 10, 39, 0.70);
    border: 1px solid rgba(104, 153, 255, 0.50);
    border-radius: 5px;
    position: relative;
    margin-bottom: 30px;

    .titleImg {
      position: absolute;
      transform: translate(0, -50%);
      top: 0;
      left: 0;
      height: 32px;
      opacity: 1;
      background: #3263eb;
      border-radius: 4px 4px 4px 0px;
      width: 96px;
      text-align: center;
      line-height: 32px;
      color: white;
    }
  }
}