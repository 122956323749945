.bodyAbout {
  width: 100%;
  padding: 0 15px;
  background: #FBFCFE;
}

.top {

  &Name {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &Block {
    background: #FFFFFF;
    box-shadow: 0px 20px 40px 0px rgba(21, 48, 85, 0.04);
    border-radius: 4px;
    padding-left: 24px;
    padding-top: 18px;
    margin-bottom: 10px;
    height: 244px;
    position: relative;

    &Title {
      font-size: 14px;
      font-weight: 500;
      color: #153055;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    &Subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #153055;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    &Desc {
      font-size: 12px;
      font-weight: 400;
      color: rgba(22, 22, 64, 0.38);
    }

    &Image {
      position: absolute;
      right: -7px;
      bottom: -7px;
    }
  }
}

.middle {

  &Name {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &Node {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 37px;
  }
}

.bottom {
  text-align: center;
  margin-bottom: 30px;

  &Name {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &Block {
    min-height: 300px;
    padding: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px 0px rgba(21, 48, 85, 0.04);

    &Image {
      margin-bottom: 9px;
    }

    &Desc {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: calc(100% - 20px);
      margin: 0 auto;
      font-size: 14px;
      font-weight: 500;
      color: rgba(22, 22, 64, 0.87);
    }
  }
}

.container {
  display: flex;
  margin-bottom: 15px;

  .prefix {
    margin-top: 16.5px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: #5D86FF;
  }

  .link {
    margin-top: 21px;
    width: 25px;
    height: 1px;
    background: #FFFFFF;
    border: 1px solid rgba(22, 22, 64, 0.12);
  }

  .card {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px 0px rgba(21, 48, 85, 0.04);
    border-radius: 4px;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;

    &Key {
      font-size: 14px;
      font-weight: 600;
      color: #335FFF;
    }

    &Time {
      margin-top: 4px;
      margin-bottom: 4px;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #153168;
    }

    &Content {
      margin-left: 10px;
      margin-bottom: 15px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(22, 22, 64, 0.87);
    }
  }
}

@media screen and (max-width: 765px) {
  .bodyAbout {
    padding-bottom: 24px;
    margin-bottom: 0;
  }
}