.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6000);
  ;
  z-index: 1;
  position: absolute;

  .maskImg {
    position: absolute;
    width: 47px;
    height: 139px;
    right: 35px;
    top: 12px;
  }

  .maskText {
    position: absolute;
    width: 203px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8700);
    line-height: 25px;
    top: 150px;
    right: 82px;
    text-align: center;
  }
}

.phoneAppDownload {
  display: none;
}

.appDownload {
  min-width: 1200px;
  margin: 0 auto;
  background: url('../../../assets/newImg/appDownloadImg/bg@2x.png') 0 0 no-repeat;
  padding: 148px auto 0 auto;
  background-size: contain;
  margin-top: -80px;
}

.title {
  width: 432px;
  height: 52px;
  font-size: 36px;
  font-family: SourceHanSansSC, SourceHanSansSC-Bold;
  font-weight: 700;
  text-align: left;
  color: #161640;
  line-height: 52px;
  margin-top: 80px;
  margin-bottom: 24px;
}

.content {
  width: 550px;
  height: 108px;
  font-size: 18px;
  font-family: SourceHanSansSC, SourceHanSansSC-Regular;
  font-weight: 400;
  text-align: left;
  color: rgba(22, 22, 64, 0.87);
  line-height: 36px;
  margin-bottom: 25px;
}

.function {
  width: 600px;
  font-size: 18px;
  font-family: SourceHanSansSC, SourceHanSansSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #161640;
  line-height: 26px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 51px;
}

.functionContent {
  display: flex;
  align-items: center;
}

.circleIcon {
  margin-right: 12px;
  color: #3bb336;
}

.functionName {
  margin-right: 24px;
}

.qrComponent {
  margin-right: 110px;
}

// .qr {
//   height: 150px;
//   width: 150px;
//   background-color: #FFFFFF;
//   border-radius: 4px;
//   box-shadow: 0px 4px 12px 0px rgba(51, 95, 255, 0.04);
//   // transition: all 2s;

//   &:hover {
//     >*:not(.androidQRCode) {
//       display: none;
//     }

//     .androidQRCode {
//       opacity: 1;
//     }
//   }

//   .androidQRCode {
//     opacity: 0;
//   }

//   &IOS:hover {
//     background: url('../../../assets/newImg/appDownloadImg/iOSQRCode.png');
//     background-size: contain;
//   }

//   &Android:hover {}

// }


.qr {
  height: 150px;
  width: 150px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgba(51, 95, 255, 0.08);
  // transition: all 2s;

  &:hover {
    >*:not(.androidQRCode) {
      display: none;
    }

    .androidQRCode {
      opacity: 1;
    }
  }

  .androidQRCode {
    opacity: 0;
  }

  &IOS:hover {
    background: url('../../../assets/newImg/appDownloadImg/iOSQRCode.png') center no-repeat;
    background-size: 128px 128px;
  }

  &WeChat:hover {
    background: url('../../../assets/newImg/appDownloadImg/weChatQRCode@2x.png') center no-repeat;
    background-size: 128px 128px;
    background-color: #FFFFFF;
    cursor: pointer;
  }

  &Android:hover {
    background: url('../../../assets/newImg/appDownloadImg/androidQRCode.png') center no-repeat;
    background-size: 128px 128px;
  }

  .phoneQrCode {
    display: none;
    padding: 11px;

    >img {
      height: 128px;
      width: 128px;
    }
  }

  &Phone:hover .phoneQrCode {
    display: block;
  }
}

.qrBg {
  width: 0;
  height: 0;
  margin-left: 90px;
  position: absolute;

  border: 30px solid #3370FF;
  border-left: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-top-right-radius: 4px;
}

.qrImg {
  height: 22px;
  width: 22px;
  margin-left: 0px;
  margin-top: -48px;
  position: relative;
}

.centerImg {
  height: 54px;
  width: 54px;
  margin-left: 48px;
  margin-top: 48px;
}

.qrs {
  width: 1080px;
  margin: 0 auto;
  display: flex;
  user-select: none;
  margin-top: -210px;
}

.logoDesc {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(22, 22, 64, 0.65);
  line-height: 22px;
  margin-top: 12px;
  text-align: center;
}

@media screen and (max-width: 765px) {
  .appDownload {
    display: none;
  }

  .phoneAppDownload {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(232deg, rgba(13, 113, 251, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

    &Content {
      width: 375px;
      height: 549px;

      &Icon {
        display: block;
        margin-top: 120px;
        margin-bottom: 20px;
        margin-left: 147px;
        width: 80px;
      }

      &Btn {
        display: block;
        margin: 0 auto;
        width: 200px;
        height: 45px;
        background: #0168FA;
        border-radius: 2px;

        font-size: 17px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 24px;
      }
    }
  }

  .phoneAppDownloadContentDesc {
    width: 200px;
    height: 60px;
    margin: 0 auto;
    margin-bottom: 80px;

    &Title {
      margin: 0 auto;
      margin-bottom: 12px;
      width: 100px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #161640;
      line-height: 28px;
    }

    &Subtitle {
      margin: 0 auto;
      width: 192px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #161640;
      line-height: 22px;
    }
  }
}

.Cando {
  display: flex;
  flex-wrap: wrap;
  width: 560px;
  margin-top: 20px;
  margin-right: -60px;

  &Item {
    display: flex;
    align-items: center;
    font-weight: 500;
    width: 140px;
    height: 40px;
    font-size: 18px;
  }

  &Img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
}

.Pic1 {
  &Img {
    width: 492px;
    height: auto;
    margin-top: 148px;
  }
}

.Pic2 {

  // margin-top: 79px;
  margin-left: 51px;

  img {
    // width: 100%;
    width: 636px;
    height: 386px;
    margin-top: 227px;
  }
}

.Pic3 {

  img {
    //width: 100%;
    // height: 776px;
    // margin-left: 500px;
    width: 764px;
    height: 776px;
    margin-top: -232px;
  }
}

.section0 {
  width: 1080px;
  margin: 0 auto;
  display: flex;
}

.section1 {
  width: 1080px;
  margin: 0 auto;
  margin-top: 80px;

  .howImg {
    // align-content: center;
    text-align: center;

    img {
      // width: 100%;
      width: 1000px;
      height: auto;
      margin-top: 40px;
    }
  }
}

.intro {
  width: 1080px;
  margin: 0 auto;
  margin-top: 80px;
  height: 360px;
  background: rgba(51, 95, 255, 0.0200);
  border: 1px solid #F0F1F7;
  backdrop-filter: blur(10px);

  &Image {
    position: absolute;
    left: 780px;
    top: 120px;
    width: 200px;
    height: auto;
  }

  &Content {
    position: absolute;
    left: 60px;
    top: 140px;

    &Title {
      font-size: 24px;
      font-weight: 600;
      color: #161640;
    }

    &Intro {
      margin-top: 16px;
      font-size: 18px;
      width: 670px;
      font-weight: 400;
      color: rgba(22, 22, 64, 0.87);
    }

    &Environment {
      margin-top: 40px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(22, 22, 64, 0.38);
    }
  }

  &Header {
    display: flex;
    width: 100%;



    &Block {
      display: flex;
      align-items: center;
      width: 20%;
      padding: 16px 29px;
      border: 1px solid #f3f4f9;
      margin-top: -1px;
      font-size: 20px;
      font-weight: 600;
      color: #000000;

      img {
        width: 48px;
        height: auto;
        margin-right: 20px;
      }

      &Selected {
        background-color: white;
        border: 1px solid #335FFF;
      }
    }
  }
}

.sectionContent {
  // display: flex;
  margin-top: 50px;
  margin-bottom: 24px;
  // margin-right: 60px;

  .heading {
    width: 269px;
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 40px;
  }

  .outline {
    margin-top: 16px;
    width: 490px;
    font-size: 18px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: rgba(22, 22, 64, 0.65);
    line-height: 34px;
  }

  button {
    width: 94px;
    height: 36px;
    background: #335FFF;
    border-radius: 2px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 35px;
    margin-left: 380px;
  }

  button:hover {
    cursor: pointer;
  }
}

.sectionContent11 {
  display: flex;
  margin-top: 50px;
  margin-bottom: 24px;
  margin-right: 60px;

  .heading {
    width: 269px;
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 40px;
  }

  .outline {
    margin-top: 16px;
    width: 490px;
    font-size: 18px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: rgba(22, 22, 64, 0.65);
    line-height: 34px;
  }

  button {
    width: 94px;
    height: 36px;
    background: #335FFF;
    border-radius: 2px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 35px;
    margin-left: 380px;
  }

  button:hover {
    cursor: pointer;
  }
}

.sectionHeader {
  .text {
    display: flex;

    .ch {
      font-size: 28px;
      font-family: SourceHanSansSC, SourceHanSansSC-Bold;
      font-weight: 700;
      text-align: left;
      color: rgba(22, 22, 64, 0.87);
      line-height: 40px;
      letter-spacing: 1px;
      margin-right: 12px;
    }

    .en {
      font-size: 28px;
      font-family: DINCondensed, DINCondensed-Bold;
      font-weight: 700;
      text-align: left;
      color: rgba(36, 36, 59, 0.16);
      line-height: 48px;
    }
  }

  .text2 {
    display: flex;
    margin-top: 79px;

    .ch {
      font-size: 28px;
      font-family: SourceHanSansSC, SourceHanSansSC-Bold;
      font-weight: 700;
      text-align: left;
      color: rgba(22, 22, 64, 0.87);
      line-height: 40px;
      letter-spacing: 1px;
      margin-right: 12px;
    }

    .en {
      font-size: 28px;
      font-family: DINCondensed, DINCondensed-Bold;
      font-weight: 700;
      text-align: left;
      color: rgba(36, 36, 59, 0.16);
      line-height: 48px;
    }
  }
}

.QRcodes {
  display: flex;
  margin-top: 60px;
  border-radius: 4px;

  .QRcode {
    width: 150px;
    height: 150px;
    box-shadow: 0px 4px 12px 0px rgba(51, 95, 255, 0.08);

    img {
      width: 54px;
      height: 54px;
      background: rgba(255, 255, 255, 1);
      border-radius: 4px;
      margin-left: 48px;
    }

    .upper {
      width: 150px;
      height: 48px;
      background: rgba(255, 255, 255, 1);

      img {
        width: 22px;
        height: 22px;
        float: right;
        z-index: 3;
        margin-right: 7px;
        margin-top: 7px;
        background-color: #3370FF;
      }

      .rect {
        width: 60px;
        height: 60px;
        background-color: #3370FF;
        margin-left: 90px;
        z-index: 1;
        border-top-right-radius: 4px;
      }
    }

    .bottom {
      width: 150px;
      height: 48px;
      background: rgba(255, 255, 255, 1);
    }

    margin-right: 110px;

    .caption {
      margin-top: 12px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(22, 22, 64, 0.65);
      line-height: 22px;
      text-align: center;
    }
  }
}

.contentBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .sectionContent {
    .heading {
      display: flex;
      width: 380px
    }

    .littleBlueRect {
      width: 8px;
      height: 30px;
      background: #345FFF;
      margin-top: 5px;
      margin-right: 8px;
    }
  }
}

.section2 {
  width: 1080px;
  margin: 0 auto;
  margin-top: 96px;
}

.section3 {
  width: 1080px;
  margin: 0 auto;

  .sectionHeader {
    margin-bottom: 50px;
  }

  .sectionContent2 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    justify-content: space-between;
    width: 1080px;

    img {
      width: 160px;
    }

    .caption {
      width: 72px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(22, 22, 64, 0.87);
      line-height: 25px;
      margin-left: 44px;
      margin-top: 11px;
    }
  }
}

.imgWrapper {
  margin-bottom: 20px;
}
