.phoneNews {
  &Container {
    display: flex;
    margin-bottom: 28px;
  }

  &Right {
    margin-left: 12px;
  }

  &Title {
    margin-bottom: 32px;
    font-size: 22px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(22, 22, 64, 0.87);
    line-height: 30px;
  }

  &MainTitle {
    margin-bottom: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: auto;
    height: 42px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(22, 22, 64, 0.87);
    line-height: 20px;
  }

  &Left {
    width: 90px;
    height: 63px;

    .phoneNewsImg {
      width: 90px;
      height: 63px;
    }
  }

  &Bottom {
    display: flex;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(22, 22, 64, 0.38);
    line-height: 17px;

    &Date {
      margin-right: auto;
    }

    &Link {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(22, 22, 64, 0.38);
      line-height: 17px;
    }
  }
}