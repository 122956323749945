.phoneFooterContainer {
  display: none;
}

.footer {
  min-width: 1200px;
  background: url('../../../assets/newImg/footerImg/footerBg.png') no-repeat, linear-gradient(227deg, #404e6b 2%, #15213a);

  >.section {
    width: 1200px;
    margin: 0 auto;
  }
}

.logo {
  margin: 47px auto 12px 55px;
  height: 36px;
}

.slogan {
  height: 100%;
  font-size: 24px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  line-height: 33px;
  margin-left: 6%;
  margin-bottom: 24px;
}

.products {
  width: auto;
}

.supports {
  width: auto;
  margin-left: 8.33%;
}

.links {
  width: auto;
  margin-left: 8.33%;
}

.infomation {
  display: flex;
  justify-content: space-evenly;
  margin-top: 24px;
}

.footerRecordNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  // height: 22px;
  opacity: 0.6;
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 17px;
  padding-bottom: 10px;
}

.footerRecordNumber a {
  opacity: 1;
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 17px;
}

.subTitle {
  width: 100px;
  opacity: 0.8;
  font-size: 18px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  line-height: 25px;
  margin-bottom: 14px;
}

.qrCodeBox {
  font-size: 10px;
  text-align: center;
  color: #ffffff;
  line-height: 25px;
  margin-bottom: 14px;
  margin-right: 30px;
  float: left;
}

.content {
  opacity: 0.6;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 22px;
  margin-bottom: 14px;
}

.consultDiv {
  margin-bottom: 12px;
}

.consultWay {
  width: 80px;
  height: 28px;
  opacity: 0.6;
  font-size: 20px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 28px;
  margin-right: 16px;
}

.consultValue {
  width: 240px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 28px;
}

.myHorizontalBar {
  height: 1px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: auto auto;
}

@media screen and (max-width: 765px) {
  .footer {
    display: none;
  }

  .phoneFooterContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(227deg, #404e6b 2%, #15213a);
  }

  .phoneFooter {
    width: auto;
    padding-bottom: 32px;
  }

  .footerLinks {
    display: block;
    margin-bottom: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 17px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .footerBtn {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
    height: 50px;
    font-size: 15px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    line-height: 21px;

    &Title {
      margin-right: auto;
    }
  }

  .footerLinkBody {
    width: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    background: rgba(0, 0, 0, 0.38);
  }

  .footerBussinessConusult {
    margin-top: 16px;
    margin-bottom: 28px;
    padding-left: 15px;
  }

  .footerAbout,
  .footerProduct,
  .footerLink {
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
  }

  .footerLogo {
    height: 20px;
    margin-left: 16px;
    margin-bottom: 6px;
  }

  .footerSlogan {
    margin-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 21px;
  }

  .footerRecordBox {
    padding-left: 16px;
    padding-right: 16px;
  }

  .footerCopyright,
  .footerRecord {
    text-align: center;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 16px;
  }

  .footerRecordLink {
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 16px;
  }
}

.spanSpace {
  display: inline-block;
  margin: 0 4px;
}

.followUs {
  margin-left: 10%;
}