.phoneHeaderContainer {
    display: none;
}

.header {
    width: 1200px;
    margin: 0 auto;
    height: 80px;
    // margin-right: 8.4%;
    // margin-left: 8.4%;
    position: relative;
    z-index: 100;

    >.sectionHeader {
        display: flex;
        height: 80px;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }
}

.logo {
    height: 31px;
    // margin: auto 30% auto 0;
}

.headerMenu {
    width: 600px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(22, 22, 64, 0.65);

    &White {
        :global {

            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
                color: #FFFFFF;
            }

            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
                border-bottom: 2px solid #FFFFFF;
            }

            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after {
                border-bottom: none;
            }

            .ant-menu-horizontal>.ant-menu-item::after,
            .ant-menu-horizontal>.ant-menu-submenu::after {
                transition: none;
            }
        }
    }

    &Blue {
        :global {

            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
                color: #335FFF;
                border-bottom-color: #335FFF;
            }

            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
                border-bottom: 2px solid #335FFF;
            }

            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
            .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after {
                border-bottom: none;
            }

            .ant-menu-horizontal>.ant-menu-item::after,
            .ant-menu-horizontal>.ant-menu-submenu::after {
                transition: none;
            }
        }
    }

  :global{
    ul{
      display: flex;
      justify-content: end;

    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
      padding: 0 0 0 30px;
    }
    .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after{
      right: 0;
      left: 30px;
    }
  }
}

.subMenuIcon {
    margin-left: 0;
    margin-right: 10px;
    height: 18px;
    width: 18px;
    text-align: center;
}

.subMenuBgColor {
    :global {
        .ant-menu {
            background: rgba(255, 255, 255, 1);
            border-radius: 4px;
        }
    }
}

@media screen and (max-width: 765px) {
    .header {
        display: none;
    }

    .phoneHeaderContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        background: #FFFFFF;
        position: relative;
        z-index: 100;
    }

    .phoneHeader {
        display: flex;
        align-items: center;
        padding-left: 25px;
        padding-right: 16px;
        width: 100%;
        height: 48px;

        &Logo {
            height: 20px;
            margin-right: auto;
        }

        &Btn {
            width: 24px;
            position: relative;
            right: 0;
        }

        &Menu {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #161640;
            line-height: 22px;
            border-right: none;
        }
    }

    .drawerMenu {
        :global {
            .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                color: #161640;
                background-color: transparent;
            }

            .ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
                border-bottom: 1px solid rgba(0, 0, 0, 0.28);
            }

            .ant-menu-submenu-selected {
                color: #161640;
            }

            .ant-drawer-header {
                border-bottom: none;
            }

            .ant-drawer-body {
                padding-top: 20px;
                padding-bottom: 0;
            }

            .ant-menu-sub.ant-menu-inline {
                background: transparent;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #161640;
                line-height: 22px;
                // padding-left: 32px;
            }

            .ant-menu-light .ant-menu-item:hover,
            .ant-menu-light .ant-menu-item-active,
            .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
            .ant-menu-light .ant-menu-submenu-active,
            .ant-menu-light .ant-menu-submenu-title:hover {
                color: #161640;
            }

            .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
            .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
                color: #161640;
            }

            .ant-menu-vertical .ant-menu-item::after,
            .ant-menu-vertical-left .ant-menu-item::after,
            .ant-menu-vertical-right .ant-menu-item::after,
            .ant-menu-inline .ant-menu-item::after {
                border-right: none;
            }

            .ant-menu-inline.ant-menu-root .ant-menu-item,
            .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.28);
            }

            .ant-menu-light .ant-menu-item {
                height: 58px;
                margin-top: 0;
                margin-bottom: 0;
                // border-bottom: 1px solid;
            }

            .ant-menu-inline .ant-menu-submenu-title {
                height: 58px;
                margin-top: 0;
                margin-bottom: 0;
                // border-bottom: 1px solid;
            }
        }
    }
}
