.sec {
  padding-bottom: 69px;
}

.title {
  height: 40px;
  margin-bottom: 60px;
  background: url('../../../../../assets/assetCopyright/whyTitle.png') 0 0 no-repeat;
  background-size: contain;
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: 45%;
  padding-bottom: 60px;

  &Title {
    padding-left: 8px;
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 30px;
    font-weight: 600;
    color: #000;
    border-left: 8px solid #345FFF;
  }

  &Body {
    font-size: 18px;
    line-height: 34px;
    color: rgba(22, 22, 64, 0.65);
    text-align: justify;
  }
}