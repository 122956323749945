.topBg {
  background: #335FFF;
  margin-top: -80px;
  text-align: center;

  >.topImgbox {
    height: 260px;
    width: 100%;
    min-width: 1200px;
    background: #335FFF url("../../../assets/newImg/contactImg/topImg@2x.png") center bottom no-repeat;
    background-size: 1200px auto;
    margin-top: -80px;
    text-align: center;
  }
}

.temp {
  display: flex;
  width: 1200px;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  margin-top: 100px;
}

.qrCodeBlockBox {
  height: 354px;
  display: flex;
  width: 1200px;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 30px;
}

.qrCodeBlock {
  display: inline-block;
  text-align: center;
  align-items: center;

  .title {
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(22, 22, 64, 0.87);
    line-height: 40px;
    margin-bottom: 39px;
  }

  .desc1 {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(22, 22, 64, 0.38);
    line-height: 22px;
    margin-top: 10px;
  }
}

.contactTitleMobile {
  display: none;
}

.contactTitlePc {
  margin: 0 auto 40px auto;
  width: 128px;
  height: 40px;
  font-size: 32px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: rgba(22, 22, 64, 0.87);
  line-height: 42px;
}

.body {
  width: 1200px;
  margin: 0 auto;
}

.contactFormContainer {
  margin: 40px auto;
  padding: 40px 0 36px 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px rgba(25, 51, 85, 0.08);
  border-radius: 2px;

  >.contactForm {
    margin: 0 auto;
    width: 368px;
    height: auto;

    button {
      width: 368px;
      height: 36px;
      border-radius: 2px;
      font-size: 15px !important;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 22px;
      letter-spacing: 0px;
      background: #335FFF;
    }
  }
}

.formInput {
  width: 270px;
}

.formInputOtherDesc {
  width: 368px;
}

.ant-form-item-label>label {
  font-size: 13px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  color: rgba(22, 22, 64, 0.65);
  line-height: 18px;
  letter-spacing: 0px;
}

.contactTip {
  margin-bottom: 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(22, 22, 64, 0.38);
  line-height: 17px;
}


@media screen and (max-width: 765px) {
  .topBg {
    height: auto;
    min-width: 0;
    background: #335FFF;
    margin-top: 0;
    text-align: center;

    >.topImg {
      margin-top: 0;
    }
  }

  .qrCodeBlockBox {
    width: 100%;
    flex-direction: column;
  }

  .body {
    width: auto;
  }

  .contactTitlePc {
    display: none;
  }

  .contactTitleMobile {
    display: block;
    margin: 32px 0 24px 16px;
    font-size: 22px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(22, 22, 64, 0.87);
    line-height: 30px;
  }

  .contactFormContainer {
    margin: 0 auto 24px auto;
    padding: 24px 0;

    >.contactForm {
      margin-right: auto;
      margin-left: auto;
      height: auto;
      width: 290px;

      button {
        display: block;
        width: 260px;
        height: 40px;
      }
    }
  }

  :global {
    .ant-form-horizontal .ant-form-item-label {
      margin-left: 20px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(21, 48, 85, 0.87);
      line-height: 22px;
    }
  }
}

:global {
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #335FFF;
    border-color: #335fff;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: #335FFF;
    border-color: #335fff;
  }
}