.box {
  min-width: 1200px;
  min-height: calc(100% - 80px);
  // margin-top: -80px;
  background-image: url(../../../assets/newImg/certImg/certBg.png);
  background-position: center;
  background-size: cover;
  padding: 65px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .formWrap {
    // color: #fff;
    color: rgba(22, 22, 64, 0.28);

    .search {
      display: flex;
      margin-bottom: 30px;

      > div {
        margin-left: 8px;
      }

      .checkBtn {
        display: none;
      }
    }

    .certBtn {
      display: block;
      margin: 0 auto;
    }
  }

  .resultWithCertPdf {
    height: 1175px;
    width: 1200px;

    .backLink {

      &.backCenter {
        //width: 64%;
        //position: relative;
        //top: 80px;
        //margin: 0 auto;
      }

      :global {
        a {
          font-size: 15px;
          font-weight: 400;
          color: rgba(22, 22, 64, 0.87);
          line-height: 21px;
        }
      }
    }

    .card {
      width: 1200px;
      display: flex;
      padding: 30px;
      margin-top: 14px;
      background: rgba(255, 255, 255, 0.4);
      box-shadow: 0px 20px 40px 0px rgba(21, 48, 85, 0.04);
      border-radius: 4px;
      border: 1px solid #FFFFFF;
      backdrop-filter: blur(10px);

      .left {
        margin-right: 30px;
        height: 566px;

        > div {
          width: 400px;
        }
      }

      .block {
        height: 321px;
        padding: 20px 20px 8px 20px;
        min-width: 700px;
        // background: rgba(0, 10, 39, 0.70);
        border: 1px solid #EEEEEE;
        border-radius: 5px;
        position: relative;
        margin: 0 auto;

        .title {
          height: 24px;
          //width: 72px;
          //text-align: center;
          display: inline-block;
          font-size: 18px;
          font-weight: 500;
          color: rgba(22, 22, 64, 0.87);
          line-height: 25px;
          border-bottom: 10px solid rgba(51, 95, 255, 0.2);
          margin-bottom: 20px;
        }
      }
    }
  }

  :global {
    .ant-btn-primary {
      background: #3263EB;
      border-color: #3263EB;
    }

    button {
      height: 56px;
      width: 56px;
      font-size: 22px;
      border-radius: 7px;
    }

    video {
      width: 1440px;
      margin-top: 80px;
    }

    .ant-descriptions-item-label {
      color: rgba(22, 22, 64, 0.47);
    }

    .ant-descriptions-item-content {
      color: rgba(22, 22, 64, 0.87);
    }

    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      margin-bottom: 12px;
      padding-bottom: 12px
    }

    .btn-position {
      width: 96px;
      height: 32px;
      background: #072884;
      border: 1px solid rgba(255, 255, 255, 0.30);
      border-radius: 5px;
      top: 16px;
      right: 20px;
      position: absolute;
    }
  }
}

.info {
  margin-bottom: 16px;

  .label {
    color: rgba(22, 22, 64, 0.38);
  }
}

:global {
  .ant-input-group-wrapper {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  .ant-input {
    height: 56px;
    width: 362px;
    padding: 4px 16px;
    margin-bottom: 12px;
    font-size: 18px;
    background: #FFFFFF;
    box-shadow: 0px 20px 40px 0px rgba(21, 48, 85, 0.04);
    border-radius: 8px;
    border: 0px;
  }

  .ant-input-group-addon {
    align-items: center;
    background: #ffffff;
    border: 0px;
    border-radius: 8px;
    width: 50px;
  }

  .ant-select-arrow {
    color: rgba(22, 22, 64, 0.87);
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 256px;
    height: 56px;
    font-size: 18px;
    background: #FFFFFF;
    box-shadow: 0px 20px 40px 0px rgba(21, 48, 85, 0.04);
    border-radius: 8px;
    border: 0px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 56px;
  }
}

@media screen and (max-width: 768px) {

  .box {
    width: auto;
    min-width: 343px;
    min-height: 0;
    padding-top: 32px;

    justify-content: flex-start;

    .formWrap {

      .search {
        flex-direction: column;
        margin-bottom: 13px;

        > div {
          margin-left: 0;
        }

        > * {
          margin-bottom: 16px;
          margin-right: 0;
        }

        .checkBtn {
          display: block;
          margin-right: auto;
          margin-left: auto;
          height: auto;
          width: 200px;
          border-radius: 2px;
          margin-top: 30px;
        }
        :global{

          .ant-input-group-addon{
            display: none;
          }

          .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child{
            border-radius: 8px;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
            max-width: 320px;
          }
        }
      }

      .certBtn {
      }
    }

    .resultWithCertPdf {
       //margin-top: -160px;
        width: auto;
        height: 980px;

      .backLink {
        padding:0;
      }
      .card {
        width: auto;
        flex-direction: column;
        padding: 0;
        margin-top: 0;

        .left {
          margin-right: 0;
          margin-bottom: 20px;
          height: auto;

          > div {
            width: auto;
            height: auto;
          }
        }

        .block {
          height: auto;
          width: 343px;
          min-width: 100px;
        }
      }

      .backLink {
        > a {
          display: none;
        }

        &.backCenter {
          width: auto;
        }

      }

    }

    :global {
      .ant-select {
        width: 100%;
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: auto;
      }

      .ant-input-group-addon {
        a {
          display: none;
        }
      }

    }
  }
  .boxCheck{
    padding: 96px 27px 24px 27px;
  }
  .boxMobile{
    .resultWithCertPdf {
      height: auto;
    }
  }
}

.pickGroupBox {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 14px 16px;
  line-height: 28px;
  background-color: #fff;
  font-size: 18px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  position: relative;
  display: table;
  border-collapse: separate;
  font-size: 18px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px 0px rgb(21 48 85 / 4%);
  border-radius: 8px;
  border: 0px;
}

.pickGroup {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.selectedGroup {
  display: block;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

