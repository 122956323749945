.phoneNews {
  display: none;
}

.pcNews {

  .container {
    width: 1080px;
    margin: 0 auto;
  }
}

.topBg {
  height: 260px;
  width: 100%;
  min-width: 1200px;
  background: #335FFF url("../../../assets/newImg/newsPageImg/news@2x.png") center bottom no-repeat;
  background-size: 1440px auto;
  margin-top: -80px;
  text-align: center;
}

.bodyNews {
  flex-basis: 1000px;
  margin-top: 30px;

  &Title {
    margin-left: 52px;
    margin-top: 70px;
    font-size: 32px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: rgba(22, 22, 64, 0.87);
    line-height: 42px;
  }
}

.newsBox {
  margin-top: 30px;
  margin-left: 40px;
}

.news:hover {
  background: rgba(255, 255, 255, 0.60);
  border: 1px solid rgba(51, 95, 255, 0.20);
  border-radius: 4px;
  box-shadow: 0px 6px 12px 0px rgba(51, 95, 255, 0.12);
}

.news {
  display: flex;
  margin-bottom: 26px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
  padding-left: 12px;
  cursor: pointer;
  border: 1px solid transparent;

  .newsLeft {

    .newsImg {
      width: 250px;
      height: 175px;
    }
  }

  .newsRight {
    .newsTitle {
      margin-top: 24px;
      margin-bottom: 14px;
      margin-left: 40px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #153055;
      line-height: 25px;
    }

    .newsDate {
      margin-left: 40px;
      margin-top: 24px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(21, 48, 85, 0.38);
      line-height: 20px;
      letter-spacing: 0px;
    }

    .newsSubTitle {
      height: 45px;
      margin-top: 12px;
      margin-left: 40px;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(21, 48, 85, 0.65);
      line-height: 22px;
      letter-spacing: 0px;
    }
  }
}

@media screen and (max-width: 765px) {
  .pcNews {
    display: none;
  }

  .phoneNews {
    display: block;
    margin-left: auto;
    margin-right: auto;

    &Box {
      padding: 0 20px;
    }

    &PageMove {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
    }

    &Title {
      margin: 32px 0 32px 16px;
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(22, 22, 64, 0.87);
      line-height: 30px;
    }
  }

  .phoneTopBg {
    background: #335FFF;

    img {
      width: 100%;
    }
  }
}