.main {
  width: 100%;
  margin-top: -80px;
  background: #F9FAFE url('../../../../../assets/assetCopyright/bg.png') 0 bottom no-repeat;
  background-size: cover;
}

.header {
  height: 600px;
  width: 1082px;
  margin: 0 auto 0;
  padding-top: 180px;
  background: url('../../../../../assets/assetCopyright/headerIcon.png') 650px 100px no-repeat;
  background-size: 409px 414px;

  &Info {
    width: 526px;
    font-size: 18px;
    letter-spacing: 1px;
    color: rgba(22, 22, 64, 0.65);
  }

  &Title {
    height: 45px;
    margin-bottom: 22px;
    background: url('../../../../../assets/assetCopyright/HeaderTitle.png') 0 0 no-repeat;
    background-size: contain;
  }

  &Button {
    width: 94px;
    height: 36px;
    margin-top: 25px;
    background: #335FFF;
    border-radius: 2px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}