.formWrap {
  display: flex;
  justify-content: space-around;
}

.form {
  :global {
    .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-progress {
      display: none;
    }

    .ant-upload.ant-upload-drag {
      width: 600px;
      height: 180px;
      background: rgba(255, 255, 255, 0.4);
      box-shadow: 0px 20px 40px 0px rgba(21, 48, 85, 0.04);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      backdrop-filter: blur(10px);
      margin-bottom: 20px;
    }

    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background: none;
    }

    .ant-upload-list-item {
      background: rgba(59, 179, 54, 0.08);

      .ant-upload-list-item-card-actions-btn {
        opacity: 1;
      }

      .anticon-delete {
        color: #3BB336;
      }
    }

    .ant-upload-list-item .ant-upload-text-icon>.anticon,
    .ant-upload-list-item .ant-upload-list-item-name {
      color: #3BB336;
      max-width: 194px;
    }

    .ant-upload-list-item-error {
      background: rgba(204, 51, 69, 0.08);

      .anticon-delete {
        color: #CC3345;
      }
    }

    .ant-upload-list-item-error,
    .ant-upload-list-item-error .ant-upload-text-icon>.anticon,
    .ant-upload-list-item-error .ant-upload-list-item-name {
      color: #CC3345;
    }
  }

  .uploadIcon {
    svg {
      width: 28px;
      height: 28px;
      color: #335fff;
    }
  }

  .text {
    margin-top: 7px;
    padding: 5px 0;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(22, 22, 64, 0.87);
    line-height: 25px;
  }

  .uploadTips {
    padding: 5px 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(22, 22, 64, 0.28);
    line-height: 20px;
  }

  .submitBtnGroup {
    padding-top: 24px;
    text-align: center;
  }
}

.bottom {
  width: 600px;

  .illustration {
    color: rgba(22, 22, 64, 0.28);
  }

  .btn {
    display: block;
    margin: 30px auto;
  }
}