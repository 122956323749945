.button {
  position: fixed;
  z-index: 1;
  right: 0;
  bottom: 5vh;
  padding: 19px 17px;
  width: 58px;
  height: 140px;
  background: #335FFF;
  border-radius: 8px 0px 0px 8px;

  .icon {
    display: block;
    margin: 0 auto 9px auto;
    width: 24px;
    height: 24px;
  }

  .label {
    margin: 0 auto;
    width: 16px;
    height: 72px;
    font-size: 16px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 18px;
  }
}

@media screen and (max-width: 765px) {
  .button {
    display: none;
  }
}