.main {
  display: flex;
  margin-top: -80px;
  margin-bottom: 70px;
  background: linear-gradient(270deg, #B2C2FF 0%, #335FFF 100%);
  box-shadow: 0px 11px 32px 0px rgba(51, 95, 255, 0.2);
}

.title {
  font-weight: 500;
  font-size: 24px;
}

.card {
  flex: 1;
  color: #fff;

  &Title {
    height: 80px;
    line-height: 80px;
    text-align: center;
    letter-spacing: 1px;
    border: 1px solid #F0F1F7;
  }

  &Sec {
    display: flex;
  }

  &Body {
    position: relative;
    height: 290px;
    padding: 35px 20px;
    border: 1px solid #F0F1F7;
    flex: 1;
    transition: flex .3s;
    box-shadow: 0px 11px 32px 0px rgba(51, 95, 255, 0.2);

    .button {
      position: absolute;
      display: none;
      right: 30px;
      bottom: 40px;
      background: none;
      color: #fff;

      &:hover {
        color: #40a9ff;
        background: #fff;
      }
    }

    &:hover {
      flex: 1.75;
      background: #3460FF url('../../../../../assets/assetCopyright/folderIcon.png') 20px bottom no-repeat;
      background-size: 122px 105px;

      .button {
        display: block;

      }
    }
  }

  &Con {
    padding-top: 24px;
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
  }
}