.phoneDocument {
  display: none;
}

.topBg {
  min-width: 1200px;
  height: 260px;
  width: 100%;
  background: #335FFF center bottom no-repeat;
  background-image: image-set(url("../../../assets/newImg/documentImg/documentBg.png") 1x,
      url("../../../assets/newImg/documentImg/documentBg@2x.png") 2x);
  background-size: 1200px auto;
  margin-top: -80px;
  text-align: center;
}

.rightBody {
  flex-grow: 1;
  padding: 50px 50px;

  img {
    max-width: 100%;
  }

  p {
    word-break: break-all;
  }

  .rightBtnsContainer {
    border-top: 1px solid rgba(22, 22, 64, 0.12);
    display: flex;
    // width: 740px;
    padding-top: 20px;

    .preBtn {
      margin-right: auto;
    }

    :global {

      .ant-btn,
      .ant-btn:active,
      .ant-btn:focus {
        border: none;
        color: #335FFF;
      }

      .ant-btn[disabled],
      .ant-btn[disabled]:hover,
      .ant-btn[disabled]:focus,
      .ant-btn[disabled]:active {
        background: none;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.leftBody {
  // width: 340px;
  border-right: solid 1px rgba(22, 22, 64, 0.12);

  .menuTitle {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-left: 65px;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    color: rgba(22, 22, 64, 0.87);
    line-height: 33px;
  }

  .menu {
    width: 200px;
    margin-top: 15px;
    margin-bottom: 50px;
    margin-left: 70px;
    margin-right: 40px;
  }

  :global {
    .ant-menu-vertical>.ant-menu-item::before {
      content: '\00A0';
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border: none;
    }

    .ant-menu {
      font-size: 15px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: rgba(22, 22, 64, 0.65);
      line-height: 21px;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: rgba(0, 0, 0, 0);
    }

    .ant-menu-item-selected {
      font-size: 15px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #161640;
      border-left: 4px #335FFF solid;
    }

    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-title:hover {
      color: #161640;
    }

    .ant-menu-vertical>.ant-menu-item:not(.ant-menu-item-selected) {
      border-left: 4px solid rgba(0, 0, 0, 0);
    }
  }
}

.body {
  width: 1200px;
  margin: 0 auto;

  .bodyContainer {
    // width: 1440px;
    // margin: 0 auto;
    // padding-left: 120px;
    // padding-right: 120px;
    display: flex;
  }
}



@media screen and (max-width: 765px) {
  .pcDocument {
    display: none;
  }

  .phoneDocument {
    display: block;
    margin-bottom: 20px;

    &Content {
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;

      img {
        max-width: 100%;
      }

      p {
        max-width: 100%;
        word-wrap: break-word;
      }
    }

    &Drawer {
      :global {
        .ant-drawer-header {
          border-bottom: none;
        }
      }

      &Menu {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #161640;
        line-height: 21px;

        :global {
          .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background: none;
            border-left: 4px solid #335FFF;
            color: #161640;
            font-weight: bold;
          }

          .ant-menu-vertical>.ant-menu-item {
            border-left: 4px solid transparent;

          }

          .ant-menu-inline,
          .ant-menu-vertical,
          .ant-menu-vertical-left {
            border-right: none;
          }

          .ant-drawer-header {
            border-bottom: none;
          }
        }
      }
    }
  }

  .phoneTopImg {
    &Container {
      background: #335FFF;

      img {
        width: 100%;
      }
    }
  }

  .phoneTopDrawerBtn {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(22, 22, 64, 0.04);
    border-radius: 0px 4px 4px 0px;
  }

  .selectedDocTitle {
    margin-bottom: 20px;
    padding-bottom: 16px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(22, 22, 64, 0.87);
    line-height: 30px;
    border-bottom: 1px solid rgba(22, 22, 64, 0.12);
  }

  .phoneDocumentBtnsContainer {
    border-top: 1px solid rgba(22, 22, 64, 0.12);
    display: flex;
    padding-top: 16px;

    .phoneDocumentNextBtn {
      margin-left: auto;
    }

    :global {

      .ant-btn,
      .ant-btn:active,
      .ant-btn:focus {
        border: none;
        color: #335FFF;
      }

      .ant-btn[disabled],
      .ant-btn[disabled]:hover,
      .ant-btn[disabled]:focus,
      .ant-btn[disabled]:active {
        background: none;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}