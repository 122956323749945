.main {
  min-width: 1440px;
  background: #ffffff;
}

.sec {
  width: 1082px;
  margin: 0 auto;
}
.bottom {
  width: 100%;
  margin: 0 auto;
}