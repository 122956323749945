.box {
  width: 510px;
  text-align: center;

  .tips {
    padding-top: 50px;
    margin-bottom: 70px;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #161640;

    .icon {
      font-size: 32px;
      margin-right: 16px;
      line-height: 32px;
      vertical-align: middle;
      color: #CC3345;
    }
  }

  .btn {
    width: 132px;
  }
}