.sec {
  padding-bottom: 69px;
}



.title {
  margin-top: 69px;
  font-weight: bold;
  font-size: 28px;
  color: rgba(22, 22, 64, 0.87);
  line-height: 40px;
  letter-spacing: 1px;
  text-align: center;
  font-style: normal;

}

.mianBack {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;

  .backmy {
    margin: 10px;
    height: 240px;
    width: 510px;
    border-radius: 12px;
    border: 1px solid #E4E5E6;

    .header {
      display: flex;
      padding-left: 25px;

      &Title {
        font-size: 24px;
        color: #067EFC;
        margin-top: 35px;

      }

      &SubTitle {
        width: 265px;
        height: 100px;
        font-size: 14px;
        color: #161640;
        margin-top: 15px;

      }
    }

    &Image {
      width: 210px;
      height: 210px;
    }
  }
}

.Top {
  height: 50px;
  margin-top: 40px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &Title {
    display: flex;
    width: 174px;
    margin-bottom: 8px;
    color: #161640;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    // &:hover {
    //   color: #FFFFFF;
    //   background: linear-gradient(270deg, #60A4FF 0%, #3B81F9 100%);
    //   border-radius: 25px;
    // }
    &.active {
      color: #FFFFFF;
      background: linear-gradient(270deg, #60A4FF 0%, #3B81F9 100%);
      border-radius: 25px;
    }
   
  }
}

.bottom {
  margin-top: 20px;
  width: 1080px;
  height: 350px;
  background: #F9FAFF;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;

  .Back {
    display: flex;
  }

  .Left {
    margin-left: 50px;
    margin-top: 40px;
    height: 240px;
    width: 478px;

    .letNum {
      font-size: 36px;
      color: #335FFF;
      height: 50px;
      font-weight: 600;
    }

    .leftTitle {
      margin-top: 12px;
      height: 33px;
      font-size: 24px;
      color: #161640;
      font-weight: 600;
    }

    .leftSubTitle {
      margin-top: 30px;
      font-size: 16px;
      color: #161640;
    }
  }

  .Right {
    height: 350px;
    width: 602px;

  }

}

.empty {
  padding: 40px;
}