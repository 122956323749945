.mobile {
  min-width: 1200px;
  min-height: calc(100% - 280px);
  // margin-top: -80px;
  background-image: url(../../assets/newImg/certImg/certBg.png);
  background-position: center;
  background-size: cover;
  padding: 65px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tit {
    margin-bottom: 15px;
  }

  .desc {
    width: 600px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(2, 23, 51, 0.87);
    line-height: 20px;

    :global {
      .ant-btn-link {
        color: #335FFF;
        padding-left: 0;
        padding-right: 0;
        width: auto;
        height: auto;
        font-size: 14px;
      }
    }
  }


  .tabCon {
    height: 300px;
    width: 600px;

    .certBtn {
      display: block;
      margin: 0 auto;
    }
  }

  :global {
    .ant-btn-primary {
      background: #3263EB;
      border-color: #3263EB;
    }

    .sum-mobile {
      margin-top: -600px;
      background: rgba(0, 22, 84, 0.70);
      border: 1px solid rgba(104, 153, 255, 0.50);
      height: 44px;
      width: 49%;
      line-height: 44px;
      text-align: center;
      color: #fff;
      position: absolute;
    }

    .ant-input-group-wrapper {
      margin-bottom: 30px;
    }

    input {
      height: 56px;
      width: 600px;
      padding: 4px 30px;
      font-size: 18px;
      border-radius: 8px;
      background: #FFFFFF;
      box-shadow: 0px 20px 40px 0px rgba(21, 48, 85, 0.04);
    }

    button {
      height: 68px;
      width: 56px;
      font-size: 22px;
      border-radius: 7px;
    }

    .ant-spin-text {
      color: #fff;
      padding-top: 24px;
      font-size: 22px;
    }

    .block {
      width: 710px;
      padding: 20px 20px 8px 20px;
      // background: rgba(0, 10, 39, 0.70);
      border: 1px solid #EEEEEE;
      border-radius: 4px;
      position: relative;
      top: 30px;
      margin: 0 auto;
      margin-bottom: 20px;

      .title {
        height: 24px;
        width: 72px;
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(22, 22, 64, 0.87);
        line-height: 25px;
        border-bottom: 10px solid rgba(51, 95, 255, 0.2);
        margin-bottom: 20px;
      }
    }

    .copyBtn {
      margin-top: 20px;
      width: 40%;
      height: 36px;
    }

    video {
      width: 1440px;
      margin-top: 80px;
    }

    .ant-descriptions-item-label {
      color: rgba(22, 22, 64, 0.47);
    }

    .ant-descriptions-item-content {
      color: rgba(22, 22, 64, 0.87);
    }

    .ant-descriptions-row>th,
    .ant-descriptions-row>td {
      margin-bottom: 12px;
      padding-bottom: 12px
    }

    .btn-position {
      width: 96px;
      height: 32px;
      background: #072884;
      border: 1px solid rgba(255, 255, 255, 0.30);
      border-radius: 5px;
      top: 16px;
      right: 20px;
      position: absolute;
    }
  }

  .succDescriptions {
    :global {
      .ant-descriptions-item-label {
        width: 80px;

        color: rgba(22, 22, 64, 0.38);
        justify-content: end;
      }
    }
  }

  .succTips {
    margin-bottom: 33px;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #161640;
  }

  .succIcon {
    font-size: 32px;
    margin-right: 16px;
    line-height: 32px;
    color: #3BB336;
  }
}

.a-button {
  height: 44px;
  background: rgba(7, 40, 132, 0.25);
  border: 1px solid #68bcff;
  line-height: 44px;
  text-align: center;
  display: inline-block;
  color: white;
}

.sum {
  .a-button;
  width: 50%;
  margin-top: 40px;
  margin-bottom: 20px;
}

.fullResult {
  // margin: 0 auto;
  height: 100%;

  .copyBtn {
    display: block;
    margin: 20px auto 0;
    width: 260px;
    height: 36px;
    font-size: 15px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
    background: #335FFF;
    border-radius: 2px;
  }

  .backLink {
    padding: 15px 0;

    &.backCenter {
      width: 1200px;
      position: relative;
      
      margin: 0 auto;
    }

    :global {
      a {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(22, 22, 64, 0.87);
        line-height: 21px;
      }
    }
  }

  .card {
    padding: 30px;
    margin-top: 14px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 20px 40px 0px rgba(21, 48, 85, 0.04);
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(10px);
  }
}

.briefResult {
  height: 778px;

  .backLink {
    padding: 15px 0;

    &.backCenter {
      width: 64%;
      position: relative;
      top: 80px;
      margin: 0 auto;
    }

    :global {
      a {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(22, 22, 64, 0.87);
        line-height: 21px;
      }
    }
  }

  .card {
    padding: 30px;
    margin-top: 14px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 20px 40px 0px rgba(21, 48, 85, 0.04);
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(10px);
  }
}

.lookUpSummary {
  width: 49%;
  height: 44px;
  margin: -200px auto;
}

.resultWithCertPdf {
  height: 1175px;

  .backLink {
    padding: 15px 0;

    &.backCenter {
      width: 64%;
      position: relative;
      top: 80px;
      margin: 0 auto;
    }

    :global {
      a {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(22, 22, 64, 0.87);
        line-height: 21px;
      }
    }
  }

  .card {
    padding: 30px;
    margin-top: 14px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 20px 40px 0px rgba(21, 48, 85, 0.04);
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(10px);
  }
}

.certImg {
  margin: 0 auto;
  // position: relative !important;
  width: 40%;
}

.formWrap {
  color: rgba(22, 22, 64, 0.28);

  :global {
    .ant-input {
      border: 0px;
    }

    .ant-input-group-addon {
      background: #ffffff;
      border: 0px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      width: 72px;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }

}

.loadingTips {
  color: #161640;
}