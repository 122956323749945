.btnTips(@c) {
  margin-top: 16px;
  font-size: 16px;
  font-family: SourceHanSansSC-Regular, SourceHanSansSC;
  font-weight: 400;
  color: @c;
  line-height: 24px;

  .btnTipsTxt {
    display: none;
  }
}

.phoneBanner {
  display: none;
}

.defaultBannerContainer {
  background: #335FFF;
  margin-top: -80px;
  padding-top: 80px;

  .sectionBanner {
    margin: auto;
    height: 100%;
  }

  .bannerImg {
    display: block;
    margin: auto;
    height: 100%;
    padding-right: 12%;
    padding-left: 12%;
  }
}

.newsBannerContainer {
  height: 600px;
  min-width: 1200px;
  margin-top: -80px;
  padding-top: 80px;

  .newsBanner {
    // width: 1440px;
    height: 600px;
    margin: auto;
    margin-top: -80px;
  }

  .bannerImg {
    height: 600px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  :global {
    .ant-carousel .slick-dots-bottom {
      bottom: 100px;
      left: -850px;
    }
    .ant-carousel .slick-dots li.slick-active button {
      width: 27px;
    }
    .ant-carousel .slick-dots li button {
      height: 5px;
      width: 18px;
    }
    .ant-carousel .slick-dots li {
      margin-right: 10px;
    }
  }
}

.bannerButtons {
  height: 160px;
  display: flex;
  position: relative;

  .sectionBannerButtons {
    display: flex;
    margin: -80px auto 0px;
  }

  .btnCollect {
    width: 360px;
    height: 160px;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(51, 95, 255, 0.08);

    &:hover {
      background: linear-gradient(270deg, #dbdfff, #ffffff);

      .btnTips {
        .btnTipsTxt {
          display: inline-block;
          animation: textScroll 0.2s linear;
        }

        .btnTipsArrow {
          display: inline-block;
          animation: arrowScroll 0.2s linear;
        }
      }
    }
  }

  .btnCert {
    .btnCollect();
    background: linear-gradient(270deg, #29fadf, #4c83ff);

    &:hover {
      background: linear-gradient(270deg, #4dffe8, #6292ff);

      .btnTips {
        .btnTipsTxt {
          display: inline-block;
          animation: textScroll 0.2s linear;
        }

        .btnTipsArrow {
          display: inline-block;
          animation: arrowScroll 0.2s linear;
        }
      }
    }
  }

  .btnBussiness {
    .btnCollect();
    background: linear-gradient(270deg, #464e84, #212955 98%);

    &:hover {
      background: linear-gradient(270deg, #6a7ae0, #20318e 98%);

      .btnTips {
        .btnTipsTxt {
          display: inline-block;
          animation: textScroll 0.2s linear;
        }

        .btnTipsArrow {
          display: inline-block;
          animation: arrowScroll 0.2s linear;
        }
      }
    }
  }
}

.btnLogo1 {
  height: 88px;
  margin-top: 22px;
  margin-left: 12px;
}

.btnContent1 {
  margin-left: 6px;
  margin-top: 34px;

  >.btnTitle1 {
    font-size: 24px;
    font-family: SourceHanSansSC, SourceHanSansSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(22, 22, 64, 0.87);
    line-height: 35px;
    letter-spacing: 1px;
  }

  >.btnSlogan1 {
    .btnTitle1();
    font-size: 18px;
    color: rgba(22, 22, 64, 0.65);
    line-height: 26px;
  }

  >.btnTips {
    .btnTips(rgba(22, 22, 64, 0.87));
  }
}

.btnLogo2 {
  height: 68px;
  margin-top: 32px;
  margin-left: 22px;
}

.btnContent2 {
  margin-left: 16px;
  margin-top: 34px;

  >.btnTitle2 {
    font-size: 24px;
    font-family: SourceHanSansSC, SourceHanSansSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 35px;
    letter-spacing: 1px;
  }

  >.btnSlogan2 {
    .btnTitle2();
    font-size: 18px;
    color: rgba(255, 255, 255, 0.80);
    line-height: 26px;
  }

  >.btnTips {
    .btnTips(#ffffff);
  }
}

.btnLogo3 {
  height: 64px;
  margin-top: 34px;
  margin-left: 24px;
}

.btnContent3 {
  margin-left: 18px;
  margin-top: 34px;

  >.btnTitle3 {
    font-size: 24px;
    font-family: SourceHanSansSC, SourceHanSansSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 35px;
    letter-spacing: 1px;
  }

  >.btnSlogan3 {
    .btnTitle3();
    font-size: 18px;
    color: rgba(255, 255, 255, 0.80);
    line-height: 26px;
  }

  >.btnTips {
    .btnTips(#ffffff);
  }
}

@media screen and (max-width: 765px) {
  .pcBanner {
    display: none;
  }

  .phoneBanner {
    display: block;
    background: #FBFCFE;
  }

  .phoneDefaultBanner {
    &Container {
      background: #335FFF;
    }

    &Img {
      display: block;
      width: 100%;
    }
  }

  .phoneNewsBanner {
    display: flex;
    align-items: center;
    justify-content: center;

    :global {
      .ant-carousel .slick-dots-bottom {
        height: 100%;
        bottom: 40px;
      }

      .ant-carousel .slick-dots li button {
        height: 100%;
        width: 4px;
        height: 4px;
        border-radius: 2px;
      }
    }
  }

  .phoneBannerBtns {
    margin-top: -32px;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;

    &Top {
      display: flex;
      margin: 0 auto;
    }

    &Bottom {
      display: flex;
      margin: 0 auto;
      margin-top: 16px;
      padding: 11px 12px;
      height: 64px;
      background: linear-gradient(270deg, #464E84 0%, #212955 100%);
      border-radius: 2px;
      border: 1px solid rgba(22, 22, 64, 0.12);

      &Left {
        margin-right: 18px;

        &Img {
          width: 42px;
        }
      }

      &Mid {
        margin-top: 3px;

        &Title {
          margin-bottom: 2px;
          font-size: 16px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.87);
          line-height: 20px;
        }

        &SubTitle {
          font-size: 10px !important;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.48);
          line-height: 14px;
        }
      }

      &Right {
        margin-top: 7px;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;

        &Arrow {
          color: #FFFFFF;
          font-size: 16px;
        }
      }
    }
  }

  .phoneBannerBtnsTopItem {
    display: flex;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 8px;
    width: 164px;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 2px rgba(152, 172, 247, 0.08);
    border-radius: 2px;
    border: 1px solid rgba(22, 22, 64, 0.12);

    &Left {
      margin-right: 7px;

      &Img {
        width: 42px;
      }
    }

    &Right {
      &Title {
        margin-top: 3px;
        margin-bottom: 2px;
        font-size: 16px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: rgba(22, 22, 64, 0.87);
        line-height: 20px;
      }

      &SubTitle {
        font-size: 10px;
        -webkit-transform: scale(0.8333333333); //无法设置小于12px的字体，遂用缩放
        margin-left: -9px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: rgba(22, 22, 64, 0.65);
        line-height: 14px;
      }
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 374px) {
  .phoneBannerBtnsTopItem {
    display: flex;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 8px;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 2px rgba(152, 172, 247, 0.08);
    border-radius: 2px;
    border: 1px solid rgba(22, 22, 64, 0.12);

    &Left {
      margin-right: 7px;

      &Img {
        width: 42px;
      }
    }

    &Right {
      margin-top: -6px;

      &Title {
        margin-top: 3px;
        margin-bottom: 2px;
        font-size: 16px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: rgba(22, 22, 64, 0.87);
        line-height: 20px;
      }

      &SubTitle {
        width: 60px;
        font-size: 12px;
        -webkit-transform: scale(0.8333333333); //无法设置小于12px的字体，遂用缩放
        margin-left: -4px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: rgba(22, 22, 64, 0.65);
        line-height: 14px;
      }
    }
  }
}

@keyframes textScroll {
  from {
    opacity: 0;
    transform: translateX(-64px);
  }

  to {
    opacity: 1;
    transform: translateX(calc(-100% + 64px));
  }
}

@keyframes arrowScroll {
  from {
    transform: translateX(-64px);
  }

  to {
    transform: translateX(calc(-100% + 16px));
  }
}
