.infoCard {
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 16px;
  padding-right: 13px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid rgba(22, 22, 64, 0.08);

  &Top {
    display: flex;
    align-items: center;

    &Img {
      margin-right: 8px;
      width: 40px;
    }

    &Name {
      height: 26px;
      margin-right: auto;
      font-size: 18px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
      color: rgba(22, 22, 64, 0.87);
      line-height: 26px;
    }
  }

  &Bottom {
    margin-top: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(21, 48, 85, 0.87);
    line-height: 24px;
  }
}

.arrowContainer {
  display: inline-block;
  width: 28px;
  height: 28px;
}

.arrowDownOutlined {
  margin-top: 6px;
  margin-left: 6px;
  font-size: 16px;
  color: #335FFF;
}

.arrowRightOutlined {
  margin-top: 6px;
  margin-left: 6px;
  font-size: 16px;
  color: rgba(22, 22, 64, 0.12);
}