.sec {
  padding-bottom: 69px;
}



.title {
  font-weight: bold;
  font-size: 28px;
  color: rgba(22, 22, 64, 0.87);
  line-height: 40px;
  letter-spacing: 1px;
  text-align: center;
  font-style: normal;

}
.mianBack
{
  margin-top: 50px;
  padding-top: 50px;
  background: #F9FAFF;
  padding-bottom: 50px;
 
}
.image
{
  display: flex;
  width: 1030px;
  height: 490px;
  margin: 0 auto;
}
