.sec {
  padding-bottom: 69px;
}



.title {
  margin-top: 69px;
  font-weight: bold;
  font-size: 28px;
  color: rgba(22, 22, 64, 0.87);
  line-height: 40px;
  letter-spacing: 1px;
  text-align: center;
  font-style: normal;

}
.mianBack
{
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  
  .backmy{
    margin: 10px;
    height: 240px;
    width: 510px;
    border-radius: 12px;
    border: 1px solid #E4E5E6;
    background: #F7F8FA;
    .header {
      display: flex;
      padding-left: 25px;
      font-weight: 600;
  
      &Title {
        font-size: 24px;
        color: #067EFC;
        margin-top: 35px;
      }
      &SubTitle {
        width: 265px;
        height: 100px;
        font-size: 14px;
        color: #161640;
        margin-top: 15px;
        white-space: pre-Line;  
      }
        
      &Img {
        margin-right: 10px;
        margin-top: 12px;
      }
    }
    &Image {
      width: 210px;
      height: 210px;
    }
  }

}

.displayLinebreak {

  white-space: pre-Line;
}

