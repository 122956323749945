.aboutPhone {
  display: none;

  &Content {
    display: none;
  }
}

.topBg {
  min-width: 1200px;
  height: 260px;
  width: 100%;
  background: #335FFF center bottom no-repeat;
  background-image: image-set(url("../../../assets/newImg/aboutImg/aboutUs.png") 1x,
      url("../../../assets/newImg/aboutImg/aboutUs@2x.png") 2x);
  background-size: 1200px auto;
  margin-top: -80px;
  text-align: center;
}

.body {
  width: 1080px;
  margin: 0 auto;
}

.top {
  text-align: center;

  &Name {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  &Block {
  
    &Title {
      font-size: 24px;
      font-weight: 500;
      color: #153055;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  
    &Subtitle {
      font-size: 18px;
      font-weight: 400;
      color: #153055;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  
    &Desc {
      font-size: 18px;
      font-weight: 400;
      color: rgba(22, 22, 64, 0.38);
      margin-top: 6px;
    }
  }
}

.middle {
  margin-top: 60px;
  text-align: center;

  &Block {
    margin-left: -60px;
    margin-right: -60px;
  }

}

.bottom {
  text-align: center;
  margin-bottom: 70px;

  &Name {
    margin-top: 50px;
    margin-bottom: 60px;
  }

  &Block {
    height: 302px;
    padding: 10px;
    border: 1px solid rgba(22, 22, 64, 0.12);

    &Image {
      margin-bottom: 9px;
    }
  
    &Desc {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 280px;
      margin: 0 auto;
      font-size: 14px;
      font-weight: 500;
      color: rgba(22, 22, 64, 0.87);
    }
  }
}

.aboutImg {
  width: 522px;
  height: 424px;
  margin-left: 224px;
}

@media screen and (max-width: 765px) {
  .body {
    display: none;
  }

  .topBg {
    display: none;
  }

  .aboutPhoneContent {
    display: flex;
  }

  .aboutPhone {
    display: block;

    &TopImg {
      &Container {
        background: #335FFF;

        img {
          width: 100%;
        }
      }
    }
  }

  .aboutImg {
    display: block;
    margin-top: 5px;
    margin-bottom: 25px;
    margin-right: auto;
    margin-left: auto;
    width: 301px;
    height: 245px;
  }
}